import React from "react";
import Particles from "react-particles-js";
import Progress from "components/progress";
import AnimationContainer from "components/animation-container";
import { isMobileOrNot } from "components/layout/index.js";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faTwitter,
  faInstagram,
  faJava,
  faCuttlefish,
  faPython,
  faJs,
  faHtml5,
  faCss3,
  faReact,
  faNodeJs,
  faGoogle,
  faGit,
  faLinux,
} from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
import "./styles.scss";
import {
  faFlask,
  faHammer,
  faTools,
  faTv,
  faCoffee,
  faTerminal,
  faEnvelope,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

class Hero extends React.Component {
  static contextType = ThemeContext;
  
  constructor(props) {
    super(props);
    this.state = {width: 0};
  }

  componentDidMount(){

    this.setState({width: window.innerWidth});

  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="about"
        style={{ height: this.context.height }}
      >
        {this.particles()}
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>About Me</h4>
              </div>
              <h3 style={this.state.width < 900 ? {color: "white", fontSize: "40px",  lineHeight: 1.1 } : { color: "white", fontSize: "2.5vw"}}>I'm a software developer with a passion for creation.</h3>
              <p style={this.state.width < 900 ? {color: "white", fontSize: "15px"} : { color: "white", fontSize: "1vw" }}>I'm a tech enthusiast with a knack for all things software.
                <br /> 
                I've been able to bring my unique solutions to unorthodox problems to fruition through frontend/backend web development, mobile development, application development, and design. 
                <br />
                Whether this means starting a new project, contributing to open source, or participating in a wide variety of hackathons, my passion for continuous learning shines above all else.
                <br />
                Feel free to connect with me at my socials below to talk about new frameworks, video games, computer engineering, or anything else that crosses your mind.
              </p>
              <div className="separator" />
              <br />
              <div className="social social_icons">
                <FontAwesomeIcon
                  icon={faGithub}
                  className="social_icon"
                  onClick={() => window.open("https://github.com/trentstauff")}
                />
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="social_icon"
                  onClick={() => window.open("mailto:tstauffe@uwaterloo.ca")}
                />
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.instagram.com/trentstauff/")
                  }
                />
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.linkedin.com/in/trentstauffer/")
                  }
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text">
              <h4  style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }}>Programming Languages</h4>
            </div>
            <Col md={10} className="service">
            <br /> <br />
              <AnimationContainer delay={200} animation="fadeInRight slow">
                <div className="icon"></div>
                <h4  style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }}>
                  Java,&nbsp;&nbsp; 
                  Python,&nbsp;&nbsp;
                  C,&nbsp;&nbsp;
                  C++,&nbsp;&nbsp;
                  C#,&nbsp;&nbsp;
                  SQL,&nbsp;&nbsp; 
                  <br></br>
                  <br></br>
                  JavaScript,&nbsp;&nbsp;
                  Golang,&nbsp;&nbsp; 
                  Dart,&nbsp;&nbsp; 
                  HTML,&nbsp;&nbsp; 
                  CSS&nbsp;&nbsp;
                </h4>
              </AnimationContainer>
            </Col>
            <br /> <br />
            <div className="line-text">
              <h4 style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }}>Frameworks and Libraries</h4>
            </div>
            <Col md={10} className="service">
            <br /> <br />
              <AnimationContainer delay={400} animation="fadeInRight slow">
                <div className="icon"></div>
                <h4 style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }}>
                  ReactJS,&nbsp;&nbsp;
                  React Native,&nbsp;&nbsp;
                  Flutter,&nbsp;&nbsp;
                  VueJS,&nbsp;&nbsp;
                  <br /> <br /> 
                  Flask,&nbsp;&nbsp; 
                  Django,&nbsp;&nbsp; 
                  NodeJS,&nbsp;&nbsp;
                  .NET,&nbsp;&nbsp; 
                  Blazor,&nbsp;&nbsp; 
                  <br /> <br />
                  JUnit,&nbsp;&nbsp; 
                  GoogleTest 
                </h4>
              </AnimationContainer>
            </Col>
            <br /> <br />
            <div className="line-text">
              <h4 style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }} >DevOps</h4>
            </div>
            <Col md={10} className="service">
              <br /> <br />
              <AnimationContainer delay={600} animation="fadeInRight slow">
                <div className="icon"></div>
                <h4 style={this.state.width < 900 ? {color: "white", fontSize: "20px"} : { color: "white", fontSize: "1.5vw" }}>
                  Linux/Windows CL,&nbsp;&nbsp; 
                  Git,&nbsp;&nbsp; 
                  Shell,&nbsp;&nbsp; 
                  Docker&nbsp;&nbsp; 
                  <br /> <br />
                </h4>
              </AnimationContainer>
            </Col>
          </Col>
        </Row>
      </section>
    );
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
            },
            size: {
              value: 1,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }
}

export default Hero;
