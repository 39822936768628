import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import AnimationContainer from "components/animation-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReact, faAngular, faAws } from "@fortawesome/free-brands-svg-icons";
import {
  faServer,
  faSmileBeam,
  faCode,
  faMoon,
  faToolbox,
  faCloud,
  faGamepad,
  faMobile,
  faRobot
} from "@fortawesome/free-solid-svg-icons";
import Counter from "components/counter";
import ThemeContext from "../../context";
import "./styles.scss";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.show = this.show.bind(this);
  }

  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== "auto"
                ? this.context.height * 0.8
                : "inherit",
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Services</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="What I Do"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== "auto"
                      ? this.context.height * 0.6
                      : "inherit",
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    );
  }

  services() {
    if (this.state.show || this.context.height === "auto") {

      if(window.innerHeight > 1300 && window.innerWidth > 1800 ){
        console.log("Hello, 1440p user." + window.innerHeight + " " + window.innerWidth)
        return (
          <Row>
            <Col md={4} className="service">
              <AnimationContainer delay={200} animation="fadeInLeft fast" >
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Front End Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                My passion for optimizing user experience has attributed me with strong front end skills. 
                I enjoy working with frameworks such as <strong>React.js, Next.js, and Vue.js . </strong>
                I love the scalability and modern development constructs that these frameworks bring!
                </p>
                {/* <p>
                  My passion for design has attributed me strong front-end
                  development skills, I've worked with various frameworks, and
                  primarily use React for its scability and modern development
                  constructs.
                </p> */}
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={600} animation="fadeInDown fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faServer} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Back End Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  As great as a good looking website is, what's more important is
                  what's under the hood. I'm adept at creating and maintaining
                  databases and providing a smooth experience for the user. 
                  My favourite frameworks to use include <strong>NodeJS, .NET Core, Flask, and Django!</strong> 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service" >
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Mobile Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  In this day and age, humans are always on the move, so I strive to create interactive and smooth apps that leave a mark in the user's mind.
                  I develop mobile appilications in <strong>React Native and Flutter. </strong> 
                  I am planning on learning <strong>Xamarin</strong> to develop apps with C# and .NET .
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service ">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faCloud} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Cloud Computing</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  We live in an ever-evolving public cloud, it allows us infinite
                  flexibility and scability, because of this I've strived to keep
                  up to date with various cloud-providers such as <strong>AWS and Docker</strong>.
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faToolbox} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Software Tool Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  Software needs to be tested. So obviously, making tools for
                  others to test their code and ensure conformity to standards is
                  important. Developing testing programs is no easy task, but it's
                  something that I take pride in doing. 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Machine Learning</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  One of the topics that grabs my attention the most is Machine Learning.
                  I've always held a passion for things like fintech and predictive modelling,
                  and in the future I plan on continuing to learn lots of different frameworks
                  and technologies to set me along this path.
                </p>
              </AnimationContainer>
            </Col>
          </Row>
        );



                // 1080p
      }else if(window.innerHeight > 950 && window && window.innerWidth > 1599){

        return (
          <Row>
            <Col md={4} className="service">
              <AnimationContainer delay={200} animation="fadeInLeft fast" >
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Front End Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                My passion for optimizing user experience has attributed me with strong front end skills. 
                I enjoy working with frameworks such as <strong>React.js, Next.js, and Vue.js . </strong>
                I love the scalability and modern development constructs that these frameworks bring!
                </p>
                {/* <p>
                  My passion for design has attributed me strong front-end
                  development skills, I've worked with various frameworks, and
                  primarily use React for its scability and modern development
                  constructs.
                </p> */}
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={600} animation="fadeInDown fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faServer} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Back End Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  As great as a good looking website is, what's more important is
                  what's under the hood. I'm adept at creating and maintaining
                  databases and providing a smooth experience for the user. 
                  My favourite frameworks to use include <strong>NodeJS, .NET Core, Flask, and Django!</strong> 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service" >
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Mobile Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  In this day and age, humans are always on the move, so I strive to create interactive and smooth apps that leave a mark in the user's mind.
                  I develop mobile appilications in <strong>React Native and Flutter. </strong>
                  I am planning on learning <strong>Xamarin</strong> to develop apps with C# and .NET .
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service ">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faCloud} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Cloud Computing</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  We live in an ever-evolving public cloud, it allows us infinite
                  flexibility and scability, because of this I've strived to keep
                  up to date with various cloud-providers such as <strong>AWS and Docker</strong>.
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faToolbox} />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Software Tool Development</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  Software needs to be tested. So obviously, making tools for
                  others to test their code and ensure conformity to standards is
                  important. Developing testing programs is no easy task, but it's
                  something that I take pride in doing. 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "0.75vw" }}>Machine Learning</h4>
                <p style={{color: "white", fontSize: "0.75vw" }}>
                  One of the topics that grabs my attention the most is Machine Learning.
                  I've always held a passion for things like fintech and predictive modelling,
                  and in the future I plan on continuing to learn lots of different frameworks
                  and technologies to set me along this path.
                </p>
              </AnimationContainer>
            </Col>
          </Row>
        );

        
      } else if (window.innerWidth < 992) {

        return (
          <Row>
            <Col md={4} className="service">
              <AnimationContainer delay={200} animation="fadeInLeft fast" >
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Front End Development</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                My passion for optimizing user experience has attributed me with strong front end skills. 
                I enjoy working with frameworks such as <strong>React.js, Next.js, and Vue.js . </strong>
                I love the scalability and modern development constructs that these frameworks bring!
                </p>
                {/* <p>
                  My passion for design has attributed me strong front-end
                  development skills, I've worked with various frameworks, and
                  primarily use React for its scability and modern development
                  constructs.
                </p> */}
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={600} animation="fadeInDown fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faServer} />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Back End Development</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                  As great as a good looking website is, what's more important is
                  what's under the hood. I'm adept at creating and maintaining
                  databases and providing a smooth experience for the user. 
                  My favourite frameworks to use include <strong>NodeJS, .NET Core, Flask, and Django!</strong> 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service" >
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Mobile Development</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                  In this day and age, humans are always on the move, so I strive to create interactive and smooth apps that leave a mark in the user's mind.
                  I develop mobile appilications in <strong>React Native and Flutter. </strong>
                  I am planning on learning <strong>Xamarin</strong> to develop apps with C# and .NET .
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service ">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faCloud} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Cloud Computing</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                  We live in an ever-evolving public cloud, it allows us infinite
                  flexibility and scability, because of this I've strived to keep
                  up to date with various cloud-providers such as <strong>AWS and Docker</strong>.
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faToolbox} />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Software Tool Development</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                  Software needs to be tested. So obviously, making tools for
                  others to test their code and ensure conformity to standards is
                  important. Developing testing programs is no easy task, but it's
                  something that I take pride in doing. 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} className="solid" />
                </div>
                <h4 style={{color: "white", fontSize: "20px" }}>Machine Learning</h4>
                <p style={{color: "white", fontSize: "20px" }}>
                  One of the topics that grabs my attention the most is Machine Learning.
                  I've always held a passion for things like fintech and predictive modelling,
                  and in the future I plan on continuing to learn lots of different frameworks
                  and technologies to set me along this path.
                </p>
              </AnimationContainer>
            </Col>
          </Row>
        );



      } else {

        return (
          <Row>
            <Col md={4} className="service">
              <AnimationContainer delay={200} animation="fadeInLeft fast" >
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Front End Development</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                My passion for optimizing user experience has attributed me with strong front end skills. 
                I enjoy working with frameworks such as <strong>React.js, Next.js, and Vue.js . </strong>
                I love the scalability and modern development constructs that these frameworks bring!
                </p>
                {/* <p>
                  My passion for design has attributed me strong front-end
                  development skills, I've worked with various frameworks, and
                  primarily use React for its scability and modern development
                  constructs.
                </p> */}
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={600} animation="fadeInDown fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faServer} />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Back End Development</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                  As great as a good looking website is, what's more important is
                  what's under the hood. I'm adept at creating and maintaining
                  databases and providing a smooth experience for the user. 
                  My favourite frameworks to use include <strong>NodeJS, .NET Core, Flask, and Django!</strong> 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faMobile} />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Mobile Development</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                  In this day and age, humans are always on the move, so I strive to create interactive and smooth apps that leave a mark in the user's mind.
                  I develop mobile appilications in <strong>React Native and Flutter. </strong> 
                  I am planning on learning <strong>Xamarin</strong> to develop apps with C# and .NET .
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faCloud} className="solid" />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Cloud Computing</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                  We live in an ever-evolving public cloud, it allows us infinite
                  flexibility and scability, because of this I've strived to keep
                  up to date with various cloud-providers such as <strong>AWS and Docker</strong>.
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service border-side">
              <AnimationContainer delay={400} animation="fadeInRight fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faToolbox} />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Software Tool Development</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                  Software needs to be tested. So obviously, making tools for
                  others to test their code and ensure conformity to standards is
                  important. Developing testing programs is no easy task, but it's
                  something that I take pride in doing. 
                </p>
              </AnimationContainer>
            </Col>
            <Col md={4} className="service">
              <AnimationContainer delay={1000} animation="fadeInUp fast">
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} className="solid" />
                </div>
                <h4 style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>Machine Learning</h4>
                <p style={ window.innerHeight > 950 ?  (window.innerWidth > 1280 ? {color: "white", fontSize: "0.6vw"} : {color: "white", fontSize: "0.7vw"}) : (window.innerWidth > 1280 ? {color: "white", fontSize: "0.7vw"} : { color: "white", fontSize: "0.9vw" })}>
                  One of the topics that grabs my attention the most is Machine Learning.
                  I've always held a passion for things like fintech and predictive modelling,
                  and in the future I plan on continuing to learn lots of different frameworks
                  and technologies to set me along this path.
                </p>
              </AnimationContainer>
            </Col>
          </Row>
        );

      }


      
    }
  }

  counters() {

    if (this.state.show || this.context.height === "auto") {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSmileBeam}
                value={100}
                text="Foosball Games Won"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={50000}
                text="Lines of Code"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faGamepad}
                value={9500}
                text="Hours in CSGO"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faMoon}
                value={999}
                text="Late Nights Spent Debugging"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      );
    }
  }
}

export default Services;
