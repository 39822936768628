import React from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import AnimationContainer from "components/animation-container";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";
import image from "../../../public/img/e7.jpg";
import Popup from "reactjs-popup";
import axios from "axios";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      error: false,
      show: false,
    };
    this.show = this.show.bind(this);
  }
  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  check(val) {
    if (this.state.error && val === "") {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.message === ""
    ) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });                         

      const params = new URLSearchParams();
      params.append("name", this.state.name);
      params.append("email", this.state.email);
      params.append("subject", this.state.subject);
      params.append("message", this.state.message);
      params.append("phone", this.state.phone);

      axios
        .post("https://trentstauffer.ca/api/contact/index.php", params, {
          withCredentials: false,
        })
        .then((response) => {
          console.log("Success! ", response);
          this.setState({ modalState: "success" });
          alert("Message Sent!");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({ modalState: "failure" });
          alert("Message Failed to Send.");
          window.location.reload();
        });
    }
  }
  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="contact"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Contact"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.show}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={5} className="form">
            {this.form()}
          </Col>
          <Col md={5} className="map">
          <img
              src={image}
              alt="car"
            />
            {/* {this.map()} */}
          </Col>
        </Row>
      </section>
    );
  }

  form() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
          <div className="form-container">
            <div className="line-text">
              <h4>Get In Touch</h4>
              <AnimationContainer delay={50} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`name ${
                      this.check(this.state.name) ? "" : "error"
                    }`}
                    placeholder="Name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={100} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`email ${
                      this.check(this.state.email) ? "" : "error"
                    }`}
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={150} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className="phone"
                    placeholder="Phone"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={200} animation="fadeInUp fast">
                <div className="form-group">
                  <textarea
                    className={`message ${
                      this.check(this.state.message) ? "" : "error"
                    }`}
                    placeholder="Message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                  ></textarea>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={250} animation="fadeInUp fast">
                <div className="submit">
                  <button
                    className={`hover-button ${
                      this.state.error ? "error" : ""
                    }`}
                    onClick={() => this.submit()}
                  >
                    <span>Send Message</span>
                  </button>
                </div>
              </AnimationContainer>
            </div>
          </div>
        </AnimationContainer>
      );
    }
  }

  map() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer
          delay={1000}
          animation="fadeIn fast"
          height={this.context.height}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%20Ayr%2C%20Ontario+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          />
        </AnimationContainer>
      );
    }
  }
}

export default Contact;
